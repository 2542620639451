<template>

  <div class="set">

    <a-spin class="loadingClass" v-if="dataValue.loadingSet" :tip="dataValue.loadingSetText"></a-spin>

    <div class="s1" v-if="!dataValue.loadingSet">
      <div class="jDiv">
        <div class="title">模拟成本报价:</div>
        <div class="setDiv"><span>采购回复价的倒数第<a-input style="width:50px;" v-inputNumber v-model:value="dataValue.set['CBBJ']['TIAO']" />条 x <a-input v-inputPrice v-model:value="dataValue.set['CBBJ']['XS']" /></span>
        </div>
      </div>
      <div class="jDiv">
        <div class="title">模拟原厂协议价:</div>
        <div class="setDiv"><span>历史最低采购价格 x <a-input v-inputPrice v-model:value="dataValue.set['LSZDCGJGBL']"/></span>
        </div>
      </div>
      <div class="jDiv">
        <div class="title">模拟二级市场波动价格:</div>
        <div class="setDiv">
          <span>使用<a-input v-inputNumber style="width:50px;" v-model:value="dataValue.set['EJSCDay']"/>的天市场均价，</span>
          <span>如果低于历史最高采购价的<a-input v-model:value="dataValue.set['LSZGJGBS']"/>倍，就是使用历史最高采购价的{{
              dataValue.set.LSZGJGBS
            }}倍。</span>
        </div>
      </div>
    </div>


    <div class="s2" v-if="!dataValue.loadingSet">
      <div class="title">系数设置：工厂库存 <span class="ms">( 原厂协议价 x 以下系数 )</span></div>
      <div class="listA">
        <div>
          <span>点击率 x &lt; 500：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['DJL_0_500']"/>
        </div>
        <div>
          <span>点击率 500 &lt;= x &lt; 1000：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['DJL_500_1000']"/>
        </div>
        <div>
          <span>点击率 1000 &lt;= x &lt; 3000：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['DJL_1000_3000']"/>
        </div>
        <div>
          <span>点击率 3000 &lt;= x &lt; 5000：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['DJL_3000_5000']"/>
        </div>
        <div>
          <span>点击率 5000 &lt;= x &lt; 10000：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['DJL_5000_10000']"/>
        </div>
        <div>
          <span>点击率 10000 &lt;= x：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['DJL_10000_0']"/>
        </div>
      </div>
      <div class="listB">
        <div>
          <span>批号 x &lt; 1年：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['PH_0_1']"/>
        </div>
        <div>
          <span>批号 1年 &lt;= x &lt; 2年：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['PH_1_2']"/>
        </div>
        <div>
          <span>批号 2年 &lt;= x &lt; 3年：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['PH_2_3']"/>
        </div>
        <div>
          <span>批号 3年 &lt;= x &lt; 5年：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['PH_3_5']"/>
        </div>
        <div>
          <span>批号 5年 &lt;= x：</span>
          <a-input v-inputPrice v-model:value="dataValue.set['GCKC']['PH_5_0']"/>
        </div>
      </div>

      <div class="title" style="padding-top: 15px;">系数设置：贸易商</div>
      <div class="mysDiv">
        <div class="v">
          <span>外资类型： x </span> <a-input v-inputPrice v-model:value="dataValue.set['MYS']['WZ']"/><span>倍</span>
        </div>
        <div class="v">
          <span>现货类型： x </span>
          <a-input v-inputPrice v-model:value="dataValue.set['MYS']['XH']"/><span>倍</span>

        </div>
      </div>
      <div class="mysDiv">
        <div class="v">
          <span>渠道类型： x </span>
          <a-input v-inputPrice v-model:value="dataValue.set['MYS']['QD']"/><span>倍</span>

        </div>
        <div class="v">
          <span>统货类型： x </span>
          <a-input v-inputPrice v-model:value="dataValue.set['MYS']['TH']"/><span>倍</span>

        </div>
      </div>
    </div>

    <div class="s3" v-if="!dataValue.loadingSet">
      <div class="title">系数设置：工厂销售</div>
      <div class="v2">
        <span>二级市场波动价格 x </span>
        <a-input v-inputPrice v-model:value="dataValue.set['GCXH']['BL']" />倍
      </div>
      <div class="title">系数设置：工厂排单 - 无目标价</div>
      <div class="v2">
        <span>中间价：模拟原厂协议价 x </span>
        <a-input v-inputPrice v-model:value="dataValue.set['GCPDWJG']" />倍
        <div>* 中间价 &gt; 模拟成本报价，使用中间价</div>
        <div>* 中间价 &lt; 模拟成本报价，使用模拟成本报价</div>
      </div>
      <div class="title">系数设置：工厂排单 - 有目标价</div>
      <div class="v2">
        <span>中间价：模拟原厂协议价 x </span>
        <a-input v-inputPrice v-model:value="dataValue.set['GCPDYJG']" />倍
        <div>* 目标价 &gt; 中间价，使用目标价</div>
        <div>* 模拟原厂协议价 &lt; 目标价 &lt; 中间价，使用中间价</div>
        <div>* 目标价 &lt; 模拟原厂协议价，放弃</div>
      </div>
    </div>

    <div class="s4" v-if="!dataValue.loadingSet">
      <a-button type="primary" @click="allEvent.setPrice.set">保存设置</a-button>
      <a-button style="float: right;" @click="allEvent.setPrice.close">关闭</a-button>
    </div>

  </div>
</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "price_c_set",
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({

      searchRun : false,
      loadingSet : true,
      loadingSetText : "正在加载中 ...",
      set : {
        "LSZDCGJGBL" : 0,
        "EJSCDay" : 0,
        "LSZGJGBS" : 0,
        "GCXH" : {
          "BL" : 0
        },
        "CBBJ":{
          "TIAO":0,
          "XS":0
        },
        "GCKC" : {
          "DJL_0_500" : 0,
          "DJL_500_1000" : 0,
          "DJL_1000_3000" : 0,
          "DJL_3000_5000" : 0,
          "DJL_5000_10000" : 0,
          "DJL_10000_0" : 0,
          "PH_0_1" : 0,
          "PH_1_2" : 0,
          "PH_2_3" : 0,
          "PH_3_5" : 0,
          "PH_5_0" : 0
        },
        "MYS" : {
          "WZ" : 0,
          "XH" : 0,
          "QD" : 0,
          "TH" : 0
        },
        "GCPDWJG":0,
        "GCPDYJG":0
      }
    });



    let allEvent = {



      setPrice : {

        get : () => {

          dataValue.loadingSet = true;
          dataValue.loadingSetText = "正在加载配置数据 ..."

          httpRequest.post('/admin/price/c.price.get', {}).then((res) => {

            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.set = res.data;
            }

            dataValue.loadingSet = false;

          })

        },

        set : () => {

          dataValue.loadingSet = true;
          dataValue.loadingSetText = "正在保存配置数据 ..."

          httpRequest.post('/admin/price/c.price.set', dataValue.set).then((res) => {

            if (res.code !== 200) {
              message.error(res.message);
            }

            allEvent.setPrice.get();

          })

        },

        close : ()=>{
          emit('goClose');
        }
      },


      init : () => {
        allEvent.setPrice.get();
      }
    }

    allEvent.init();

    return {
      dataValue,
      allEvent
    }
  }
}
</script>

<style scoped>

.set { bottom: 0; width: 100%; float: left;
  margin: 0; padding: 0;  }
.set .title { font-weight: bold; margin: 10px 0 12px 0; clear: both; }


.set .s1 { width: 100%; float: left; border-bottom: 1px solid #cccccc;
  padding-right: 40px; height: 320px; }
.set .s1 .jDiv { clear: both; float: left; }
.set .s1 input { width: 70px; text-align: center; margin: 0 6px 0 6px; }
.set .s1 .setDiv { margin-left: 10px; line-height: 40px; }


.set .s2 { width: 100%; float: left;
  height: 450px; border-bottom: 1px solid #cccccc; margin-top: 20px; }
.set .ms { color: #444444; font-weight: normal; }
.set .s2 input { width: 70px; text-align: center; float: left; }
.set .s2 .listA { float: left; margin-left: 20px; }
.set .s2 .listA span { float: left; width: 190px; }
.set .s2 .listA > div { float: left; clear: both; line-height: 40px; }
.set .s2 .listB { float: left; margin-left: 50px; }
.set .s2 .listB span { float: left; width: 150px; }
.set .s2 .listB > div { float: left; clear: both; line-height: 40px; }
.set .s2 .mysDiv{ float: left; clear: both; margin-bottom: 10px; margin-left: 10px; }
.set .s2 .mysDiv span { margin: 0 10px 0 10px; float: left; line-height: 30px; }
.set .s2 .mysDiv .v { margin-right: 20px; float: left; }

.set .s3 { width: 100%; float: left; border-bottom: 1px solid #cccccc; height: 440px; margin-top: 30px; }
.set .s3 span { line-height: 40px; }
.set .s3 .v2 { margin-left: 20px; float: left; clear: both; width: 100%; margin-bottom: 15px; }
.set .s3 .v2 div{ color: #666666; line-height: 29px; }
.set .s3 input { width: 60px; text-align: center; margin: 0 5px 0 5px; }

.set .s4 { float: left; line-height: 100%; margin-top: 40px; width: 100%; }

b{ font-weight: bold; }

</style>